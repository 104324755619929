#tekst {
    .employee {
        img {
            width: 100%;
            margin-bottom: 30px;
        }

        h2 {
            margin: 0 0 5px 0;
        }

        h3 {
            margin: 0 0 5px 0;
            font-size: 16px;
            color: #000;
            font-weight: bold;
        }

        .phone {
            margin: 0 0 5px 0;
        }
    }
    
    .contact_wrapper {
        padding: 15px;
        background: #CACCE5;

        h2 {
            color: #1F3B84;
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
    #tekst {
        .employee {
            img {
                width: 60%;
            }
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
}