#homepage {
    
    h2 {
        font-size: 28px;
        font-weight: 700;
        margin: 0 0 15px 0;
        color: $color1;
        line-height: 1.2;
    }
    
    #actueel {
        .slick-dots {
            width: 100%;
            text-align: center;
            list-style: none;
            padding: 0px;
            margin: 15px 0 0 0;

            li {
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background: #5b5b5b;
                margin: 0px 3px 5px 3px;

                &.slick-active {
                    background: $color2;
                }

                button {
                    display: none;
                }
            }
        }
    }

    #usps_wrapper {
        text-align: center;
        #usps {
            a {
                display: block;
                color: #FFF;
                text-decoration: none;
            }
            
            .hexagon {
                display: inline-block;
                position: relative;
                width: 200px; 
                height: 115.47px;
                background-color: shade($color2, 25%);
                margin: 57.74px 0;

                span {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    font-size: 100px;
                    transform: translate(-50%, -50%);
                }

                &:before,
                    &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 0;
                    border-left: 100px solid transparent;
                    border-right: 100px solid transparent;
                }

                &:before {
                    bottom: 100%;
                    border-bottom: 57.74px solid shade($color2, 25%);
                }

                &:after {
                    top: 100%;
                    border-top: 57.74px solid shade($color2, 25%);
                }
            }

            h2 {
                color: #FFF;
                font-size: 22px;
            }

            p {
                margin-bottom: 0px;
            }

            .slick-dots {
                width: 100%;
                text-align: center;
                list-style: none;
                padding: 0px;
                margin: 15px 0 0 0;

                li {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border-radius: 4px;
                    background: rgba(#FFF, 0.5);
                    margin: 0px 3px 5px 3px;

                    &.slick-active {
                        background: #FFF;
                    }

                    button {
                        display: none;
                    }
                }
            }
        }

        .btn_wrapper {
            margin-top: 15px;
        }
    }

    #nieuws_wrapper {
        text-align: center;
        #nieuws {
            .slide {
                a {
                    display: block;
                    background: #FFF;
                    text-decoration: none;
                    color: $color1;
                    border: 1px solid #e0e0e0;
                    border-radius: 4px;

                    figure {
                        position: relative;
                        margin: 0px;

                        img {
                            width: 100%;
                        }
                    }

                    .text {
                        padding: 15px;
                        height: 110px;

                        time {
                            font-family: $font1;
                            color: #5b5b5b;
                            font-size: 16px;
                            font-weight: 400;
                        }

                        h2 {
                            font-family: $font1;
                            margin: 0;
                            font-size: 22px;
                        }
                    }
                }
            }

            .slick-dots {
                width: 100%;
                text-align: center;
                list-style: none;
                padding: 0px;
                margin: 15px 0 0 0;

                li {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border-radius: 4px;
                    background: #5b5b5b;
                    margin: 0px 3px 5px 3px;

                    &.slick-active {
                        background: $color2;
                    }

                    button {
                        display: none;
                    }
                }
            }
        }

        .btn_wrapper {
            text-align: center;
            margin-top: 15px;
        }
    }
}

.score-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%
}

.score {
    display: none;
    position: absolute;
    top: 200px;
    left: 0px;
    width: 164px;
    height: 164px;
    background: url("../img/button-funda.png") transparent;
    background-size: 100%;
    z-index: 9;
    font-family: $font1;
    font-size: 42px;
    font-weight: bold;
    text-align: center;
    padding: 50px;
    color: $color1;
    background-repeat: no-repeat;
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
    #homepage {
        h2 {
            font-size: 32px;
        }
    }

    .score {
        display: block;
        top: 150px;
        left: 15px;
        width: 100px;
        height: 100px;
        font-size: 30px;
        padding: 28px 0;
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
    #homepage {
        #usps_wrapper {
            .btn_wrapper {
                margin-top: 30px;
            }
        }

        #nieuws_wrapper {
            text-align: left;

            .btn_wrapper {
                margin-top: 30px;
            }
        }
    }

    .score {
        display: block;
        top: 150px;
        left: 15px;
        width: 100px;
        height: 100px;
        font-size: 30px;
        padding: 28px 0;
    }
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
    #homepage {
        h2 {
            font-size: 42px;
        }
        
        .text-content {
            position: relative;
            background: url(/img/thumbsup.jpg) 30px bottom no-repeat;
            background-size: 45%;
        }
    }

    .score {
        display: block;
        top: 200px;
        left: 0;
        width: 164px;
        height: 164px;
        font-size: 42px;
        padding: 50px 0;
    }
}