#contact {
    .maps_wrapper {
        position: relative;
        padding-bottom: 20%;
        
        #maps {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    
    dl {
        line-height: 2;
        margin-bottom: 30px;
        
        dt {
            width: 150px;
            float: left;
            font-weight: bold;
            display: none;
        }
        
        dd {
            margin: 0;
            
            span {
                display: inline-block !important;
            }
        }
        
        + img {
            width: 100%;
            margin-bottom: 30px;
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
    #contact {
        dl {
            dt {
                display: block;
            }
            
            + img {
                width: 60%;
                margin-bottom: 30px;
            }
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
}