.label {
    position: absolute;
    left: 0;
    top: 0;
    background: url(/img/labels.png) 0 0 no-repeat;
    background-size: 133px auto;
    width: 133px;
    height: 116px;
    
    &.label-nieuw {
        background-position: 0 0;
    }
    
    &.label-open-huis {
        background-position: 0 -117px;
    }
    
    &.label-verkocht {
        background-position: 0 -234px;
    }
    
    &.label-verhuurd {
        background-position: 0 -351px;
    }
    
    &.label-verkocht-onder-voorbehoud {
        background-position: 0 -468px;
    }
    
    &.label-verhuurd-onder-voorbehoud {
        background-position: 0 -585px;
    }
    
    &.label-prijs-verlaagd {
        background-position: 0 -702px;
    }
}