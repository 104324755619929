#search {
    display: none;
    position: absolute;
    left: 0px;
    top: 75px;
    z-index: 10;
    background: #FFF;
    width: 100%;
    transform: all 0.3s;
    padding: 15px 0 0 0;

    &:after {
        content: " ";
        width: 100%;
        display: block
    }

    form {
        @extend %clearfix;

        .btn {
            border: none;
            background: $color2;
            color: #FFF;
            width: 100%;
        }

        .form-group {
            @extend .col-xs-12;

            .control-label {
                display: none;
            }

            .form-control {
                padding: 10px 15px;
                height: 42px;
            }
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
    #search {
        display: block !important;
        position: static;
        padding: 30px 0;

        .search-wrapper.wrapper-gray & {
            background: #F5F5F5;
        }

        form {
            .form-group {
                width: 33.333333%;
            }
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {

    #search {
        form {
            .form-group {
                width: 16.66666666666667%;
                margin-bottom: 0px;
            }
        }
    }

}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {

    #search {
        form {
            .form-group {
                .form-control {
                    padding: 10px 15px;
                    height: 42px;
                }
            }
        }
    }
}